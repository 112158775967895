.filter-pill {
  background: var(--background-gray);
  cursor: pointer;
  border: none;
  border-radius: 160px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 12px 8px 16px;
  display: flex;
}

.filter-pill .body-standard-regular {
  color: var(--primary-dark-1);
  margin-right: 4px;
}

.filter-pill:hover {
  background: var(--background-divider);
}

@media only screen and (device-width <= 390px) {
  .filter-pill {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .filter-pill {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

.filter-search-item {
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 4px;
  min-height: 20px;
  padding: 14px 8px;
  display: flex;
  overflow-x: hidden;
}

.filter-search-item:hover, .filter-highlighted {
  background: var(--secondary-green-hue);
}

.filter-search {
  background: var(--background-white);
  border: 1px solid var(--background-divider);
  cursor: text;
  border-radius: 8px;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  min-width: 180px;
  max-width: 180px;
  display: flex;
  position: relative;
}

.filter-search .filter-search-wrapper {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 8px 0 8px 16px;
  display: flex;
}

.filter-search .filter-search-wrapper .search-icon {
  background-image: url("search.90786390.svg");
  background-size: 12px;
  width: 12px;
  height: 12px;
  display: flex;
}

.filter-search .filter-search-wrapper .filter-input {
  border: none;
  flex-grow: 1;
  width: 110px;
  display: flex;
  position: relative;
}

.filter-search .filter-search-wrapper .filter-input:focus {
  outline: none;
}

.filter-search .filter-search-wrapper .filter-input:focus::placeholder {
  color: #0000;
}

.filter-search .filter-dropdown-dropdown {
  background: var(--background-white);
  z-index: 1;
  cursor: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 10px;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
  width: 155px;
  max-height: 300px;
  padding: 16px 12px;
  display: flex;
  position: absolute;
  top: 42px;
  left: 0;
  overflow-y: auto;
  box-shadow: 2px 2px 30px #0000000d;
}

.filter-search .filter-dropdown-dropdown::-webkit-scrollbar {
  display: none;
}

.sort-dropdown-item {
  cursor: pointer;
  border-radius: 4px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding: 14px 16px;
  display: flex;
}

.sort-dropdown-item .sort-arrow {
  width: 9px;
  height: 13px;
}

.sort-dropdown-item .up {
  background-image: url("arrow.bac06411.svg");
  transform: rotate(180deg);
}

.sort-dropdown-item .down {
  background-image: url("arrow.bac06411.svg");
}

.sort-dropdown-item-checked {
  background: var(--secondary-green-hue);
}

.sort-dropdown-item-not-checked:hover {
  background: var(--background-divider);
}

.sort-dropdown-item-not-checked:hover .ascDesc {
  background-image: url("arrow-up-gray.be69c5e8.svg");
}

.tiny-button {
  background: var(--background-divider);
  color: #000;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
}

.tiny-button:hover {
  color: var(--primary-dark-2);
}

.tiny-button:active {
  background: var(--primary-dark-1);
  color: var(--primary-dark-4);
}

.tiny-button-disabled {
  background: var(--background-divider);
  color: var(--primary-dark-2);
  cursor: auto;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
}

.tiny-button-disabled:hover {
  color: var(--primary-dark-2);
}

.tiny-button-disabled:active {
  background: var(--background-divider);
  color: var(--primary-dark-2);
}

.sort-dropdown {
  background: var(--background-white);
  z-index: 10;
  cursor: auto;
  border-radius: 10px;
  flex-direction: column;
  gap: 5px;
  width: 263px;
  padding: 16px 12px;
  display: flex;
  position: absolute;
  top: 50px;
  left: -245px;
  box-shadow: 2px 2px 30px #0000000d;
}

.sort-dropdown .dropdown-header {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.hidden {
  visibility: hidden;
  display: none;
}

.sort-button {
  cursor: pointer;
  border-width: 0;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
}

.sort-button img {
  filter: invert();
}

.sort-button .checkbox {
  visibility: hidden;
  display: none;
}

.sort-button-checked, .sort-button-checked:hover {
  background: var(--primary-green);
}

.sort-button-not-checked {
  background: var(--primary-dark-1);
}

.sort-button-not-checked:hover {
  background: var(--primary-dark-2);
}

.sort-button-disabled {
  background: var(--primary-dark-2);
  cursor: auto;
}

.sort-button-disabled:hover {
  background: var(--primary-dark-2);
}

@media only screen and (device-width <= 390px) {
  .sort-button {
    margin-right: 24px;
  }
}

.admin-sort-filter {
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 80%;
  margin: 48px 41px 42px;
  display: flex;
}

.admin-sort-filter .admin-filters-sort {
  flex-flow: wrap;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
}

.admin-sort-filter .admin-filters-sort .sort-button {
  background-color: var(--primary-dark-5);
  margin-left: auto;
  display: flex;
}

.admin-sort-filter .admin-filter-pills {
  flex-flow: wrap;
  align-items: center;
  gap: 8px;
  display: flex;
}

@media only screen and (device-width <= 390px) {
  .admin-sort-filter {
    gap: 4px;
    width: 90%;
    margin: 20px 10px 20px 15px;
  }

  .admin-sort-filter .admin-sort-filter-wrapper {
    gap: 4px;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .admin-sort-filter {
    gap: 6px;
    width: 90%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.app-footer {
  background-color: var(--secondary-gray);
  flex-direction: column;
  flex-shrink: 0;
  align-items: stretch;
  width: 100%;
  display: flex;
}

.app-footer .footer-logos {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin: 32px 39px 28px 62px;
  display: flex;
}

.app-footer .footer-logos .cst-logo-monochrome {
  width: 120px;
  margin-right: auto;
}

.app-footer .footer-logos .footer-social-logo {
  cursor: pointer;
}

.app-footer .footer-links {
  flex-flow: wrap;
  align-items: center;
  gap: 16px;
  max-width: 465px;
  margin-bottom: 32px;
  margin-left: 62px;
  margin-right: 20px;
  display: flex;
}

.app-footer .footer-links .footer-link {
  cursor: pointer;
  color: var(--background-white);
  text-decoration: none;
}

.app-footer .footer-copyright {
  color: var(--primary-dark-3);
  margin-bottom: 46px;
  margin-left: 62px;
}

.app-footer .footer-extra-height {
  visibility: hidden;
  display: none;
}

@media only screen and (device-width <= 390px) {
  .app-footer .footer-logos .cst-logo-monochrome {
    width: 61px;
  }

  .app-footer .footer-logos {
    margin-left: 31px;
  }

  .app-footer .footer-links {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 31px;
  }

  .app-footer .footer-copyright {
    margin-left: 31px;
  }

  .app-footer .footer-extra-height {
    visibility: inherit;
    display: inherit;
    height: 80px;
  }
}

@media only screen and (device-width >= 390px) and (device-width <= 744px) {
  .app-footer .footer-extra-height {
    visibility: inherit;
    display: inherit;
    height: 80px;
  }
}

.clear-button {
  cursor: pointer;
  background: var(--background-divider);
  z-index: 1;
  border: none;
  border-radius: 55px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.clear-button:hover {
  background: var(--primary-dark-4);
}

.modal-textbox {
  background-color: var(--background-gray);
  border: 1px solid;
  border-color: var(--background-divider);
  cursor: text;
  border-radius: 8px;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
  padding: 10px 22px 10px 16px;
  display: flex;
}

.modal-textbox .modal-textbox-label {
  color: var(--primary-dark-2);
}

.modal-textbox .modal-textbox-label .modal-textbox-required {
  color: var(--secondary-attention);
  margin-left: 2px;
}

.modal-textbox .modal-textbox-input {
  background: var(--background-gray);
  border: none;
}

.modal-textbox .modal-textbox-input:focus {
  outline: none;
}

.modal-textbox-focused {
  border-bottom: 1px solid var(--primary-green);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.primary-button {
  background: var(--primary-dark-1);
  cursor: pointer;
  border: none;
  border-radius: 160px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 40px;
  display: flex;
}

.primary-button .body-large-semibold {
  white-space: nowrap;
}

.primary-button:hover {
  background: var(--primary-dark-2);
}

.primary-button:active {
  background: var(--primary-green);
}

.toast-message {
  z-index: 14;
  opacity: 0;
  background: #fff;
  border: 1px solid #ecbcbc;
  border-radius: 8px;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  width: 70%;
  max-width: 600px;
  min-height: 51px;
  margin: 5px;
  padding: 10px 16px 10px 8px;
  transition: opacity .5s, transform .5s;
  display: flex;
  position: fixed;
  top: -30%;
  left: 50%;
  transform: translate(-50%, -100%);
  box-shadow: 0 0 150px #00000026;
}

.toast-message.toast-visible {
  opacity: 1;
  top: 10%;
  transform: translate(-50%);
}

.toast-message.toast-hidden {
  opacity: 0;
  top: -30%;
  transform: translate(-50%, -100%);
}

.toast-message.success {
  background: linear-gradient(#94f0941a 0%, #00d6001a 100%), #fff;
  border: 1px solid #8dd28c;
}

.toast-message.error {
  background: linear-gradient(#f0a3a31a 0%, #d600001a 100%), #fff;
  border: 1px solid #d60000;
}

.toast-message .toast-message-nav-wrapper {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.toast-message .toast-message-nav-wrapper .toast-message-header {
  color: var(--primary-dark-1);
  align-self: center;
  margin-left: 10px;
}

.toast-message .toast-message-nav-wrapper .toast-message-back {
  color: var(--primary-dark-1);
  cursor: pointer;
  background-image: url("arrow-red.0275f603.svg");
  width: 14px;
  height: 10px;
  margin-left: auto;
  transition: transform .3s;
}

.toast-message .toast-message-nav-wrapper .toast-message-back:hover {
  color: var(--primary-dark-2);
  background-image: url("back-arrow-hover.1fd1fd06.svg");
  transform: rotate(180deg);
}

.toast-message .toast-message-body {
  color: var(--primary-dark-2);
  margin-left: 23px;
}

@media only screen and (device-width <= 744px) {
  .toast-message {
    gap: 8px;
    width: 90%;
    padding: 16px;
    top: 0%;
  }

  .toast-message-nav-wrapper {
    gap: 5px;
  }
}

.help-modal {
  z-index: 11;
  background-color: #00000080;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.help-modal .help-modal-wrapper {
  background: var(--background-white);
  z-index: 12;
  border-radius: 10px;
  flex-direction: column;
  gap: 16px;
  width: 70%;
  max-width: 605px;
  margin: auto;
  padding: 30px 39px 20px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 4px #00000014;
}

.help-modal .help-modal-wrapper .help-modal-body {
  color: var(--primary-dark-2);
  margin-bottom: 34px;
}

.help-modal .help-modal-wrapper .help-modal-nav-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.help-modal .help-modal-wrapper .help-modal-nav-wrapper .help-modal-back {
  color: var(--primary-dark-1);
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.help-modal .help-modal-wrapper .help-modal-nav-wrapper .help-modal-back .back-arrow {
  background-image: url("back-arrow.939b1895.svg");
  width: 14px;
  height: 10px;
  margin-right: 7px;
}

.help-modal .help-modal-wrapper .help-modal-nav-wrapper .help-modal-back:hover {
  color: var(--primary-dark-2);
}

.help-modal .help-modal-wrapper .help-modal-nav-wrapper .help-modal-back:hover .back-arrow {
  background-image: url("back-arrow-hover.1fd1fd06.svg");
}

.help-modal .help-modal-wrapper .primary-button {
  align-self: center;
  margin-top: 16px;
}

.help-modal .help-modal-wrapper .toast-message {
  z-index: 1000;
  top: 0%;
}

.help-modal .help-modal-wrapper .help-modal-success-header {
  color: var(--secondary-success);
  flex-direction: row;
  align-self: center;
  align-items: center;
  gap: 14px;
  margin-top: 50px;
  display: flex;
}

.help-modal .help-modal-wrapper .help-modal-success-text {
  text-align: center;
  max-width: 456px;
  color: var(--primary-dark-2);
  align-self: center;
  margin-bottom: 50px;
}

.help-modal-hidden {
  visibility: hidden;
  display: none;
}

@media only screen and (device-width <= 744px) {
  .help-modal > .help-modal-wrapper {
    width: 75%;
    padding: 32px;
  }
}

.app-header {
  background: var(--background-white);
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  height: 18px;
  padding: 36px 39px;
  display: flex;
}

.app-header .logo {
  cursor: pointer;
  background-image: url("duidash-logo-dark.b3d1d3e2.png");
  background-repeat: no-repeat;
  background-size: 120px;
  flex: none;
  order: 0;
  align-self: flex-start;
  width: 120px;
  height: 40px;
  margin-top: -5px;
}

.app-header .nav-buttons {
  flex-direction: row;
  display: flex;
}

.app-header .nav-buttons .help-button {
  color: var(--primary-dark-1);
  cursor: pointer;
  margin-left: 4px;
  margin-right: 36px;
  text-decoration: none;
  display: flex;
}

.app-header .nav-buttons .nav-link-profile, .app-header .nav-buttons .nav-link-login {
  cursor: pointer;
  margin-left: 4px;
  margin-right: 4px;
  text-decoration: none;
  display: flex;
}

.app-header .nav-buttons .nav-link-profile .profile-icon, .app-header .nav-buttons .nav-link-login .profile-icon {
  margin-right: 8px;
}

.app-header .nav-buttons .nav-link-profile .body-standard-semibold, .app-header .nav-buttons .nav-link-login .body-standard-semibold {
  color: var(--primary-dark-1);
  flex: none;
  order: 0;
  text-decoration: none;
}

@media only screen and (device-width <= 390px) {
  .app-header {
    padding: 24px 20px;
  }

  .app-header .logo {
    background-size: 87px;
    width: 87px;
    height: 33px;
  }

  .app-header .nav-buttons > .help-button {
    margin-right: 16px;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .app-header {
    padding-top: 19px;
    padding-bottom: 19px;
  }
}

.card-affiliate .card-affiliate-name .affiliate-name {
  color: var(--primary-dark-1);
  margin-top: 20px;
}

.card-affiliate:hover {
  box-shadow: 2px 2px 4px #00000014;
}

@media only screen and (device-width <= 744px) {
  .card-affiliate {
    border-radius: 20px;
    flex-direction: column;
    margin-left: 21px;
    margin-right: 21px;
    padding: 28px 22px 20px;
    overflow: hidden;
    box-shadow: 2px 2px 4px #00000014;
  }

  .card-affiliate .card-affiliate-name {
    align-self: flex-start;
  }

  .card-affiliate .card-affiliate-firm {
    align-items: flex-start;
  }

  .affiliate-unverfied {
    background: linear-gradient(90deg, #ffc95833 0%, #fff8e933 100%), #fff;
  }

  .affiliate-verfied {
    background: linear-gradient(90deg, #2b93ff33 0%, #e4f1ff33 100%), #fff;
  }
}

@media only screen and (device-width <= 390px) {
  .card-affiliate {
    flex-direction: column;
  }
}

.invite-modal {
  z-index: 11;
  background-color: #00000080;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.invite-modal .invite-modal-wrapper {
  background: var(--background-white);
  z-index: 12;
  border-radius: 10px;
  flex-direction: column;
  gap: 16px;
  width: 70%;
  max-width: 605px;
  margin: auto;
  padding: 30px 39px 20px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 4px #00000014;
}

.invite-modal .invite-modal-wrapper .error-message {
  color: var(--secondary-attention);
  font-family: Roboto;
  font-size: .9rem;
}

.invite-modal .invite-modal-wrapper .invite-modal-body {
  color: var(--primary-dark-2);
  margin-bottom: 34px;
}

.invite-modal .invite-modal-wrapper .invite-modal-nav-wrapper {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.invite-modal .invite-modal-wrapper .invite-modal-nav-wrapper .invite-modal-back {
  color: var(--primary-dark-1);
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.invite-modal .invite-modal-wrapper .invite-modal-nav-wrapper .invite-modal-back .back-arrow {
  background-image: url("back-arrow.939b1895.svg");
  width: 14px;
  height: 10px;
  margin-right: 7px;
}

.invite-modal .invite-modal-wrapper .invite-modal-nav-wrapper .invite-modal-back:hover {
  color: var(--primary-dark-2);
}

.invite-modal .invite-modal-wrapper .invite-modal-nav-wrapper .invite-modal-back:hover .back-arrow {
  background-image: url("back-arrow-hover.1fd1fd06.svg");
}

.invite-modal .invite-modal-wrapper .primary-button {
  align-self: center;
  margin-top: 16px;
}

.invite-modal .invite-modal-wrapper .invite-modal-success-header {
  color: var(--secondary-success);
  flex-direction: row;
  align-self: center;
  align-items: center;
  gap: 14px;
  margin-top: 50px;
  display: flex;
}

.invite-modal .invite-modal-wrapper .invite-modal-success-text {
  text-align: center;
  max-width: 456px;
  color: var(--primary-dark-2);
  align-self: center;
  margin-bottom: 50px;
}

.invite-modal .invite-modal-wrapper .invite-modal-success-text .invite-success-email {
  color: var(--primary-dark-1);
}

.form-group {
  background-color: var(--background-gray);
  border: 1px solid;
  border-color: var(--background-divider);
  cursor: text;
  border-radius: 8px;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
  padding: 10px 22px 10px 16px;
  font-family: Roboto;
  display: flex;
}

.form-group label {
  color: var(--primary-dark-2);
}

.form-group select {
  background: var(--background-gray);
  color: var(--primary-dark-1);
  cursor: pointer;
  border: none;
  padding: 8px 0;
  font-family: Roboto;
  font-size: 1rem;
}

.form-group select:focus {
  border-bottom: 1px solid var(--primary-green);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  outline: none;
}

.form-group .error-message {
  color: var(--secondary-attention);
  font-size: .875rem;
}

.invite-modal-hidden {
  visibility: hidden;
  display: none;
}

@media only screen and (device-width <= 744px) {
  .invite-modal > .invite-modal-wrapper {
    width: 75%;
    padding: 32px;
  }
}

.invite-button {
  background: var(--background-white);
  color: #000;
  border: 1px solid var(--background-divider);
  cursor: pointer;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  min-width: 174px;
  padding: 12px;
  display: flex;
}

.invite-button .invite-icon {
  background: var(--primary-dark-1);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.invite-button:hover {
  background: var(--background-gray);
}

.invite-button:hover .invite-icon {
  background: var(--primary-dark-2);
}

.invite-button:active {
  background: var(--background-white);
  color: var(--primary-green);
  border-color: var(--primary-green);
}

.invite-button:active .invite-icon {
  background: var(--primary-green);
}

@media only screen and (device-width <= 744px) {
  .invite-button {
    margin-left: 21px;
    margin-right: 5px;
  }
}

.search-dropdown-item {
  cursor: pointer;
  border-radius: 10px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  padding: 16px 40px 16px 30px;
  display: flex;
}

.search-dropdown-item img {
  visibility: hidden;
  margin-right: 12px;
}

.search-dropdown-item .search-name {
  color: var(--primary-dark-2);
  justify-self: flex-start;
  margin-right: auto;
}

.search-dropdown-item .search-id-label {
  color: var(--primary-dark-3);
  margin-right: 15px;
}

.search-dropdown-item .search-id {
  color: var(--primary-dark-3);
}

.highlight {
  color: var(--primary-dark-1);
}

.search-dropdown-item:hover {
  background: var(--secondary-green-hue);
}

.search-dropdown-item:hover img {
  visibility: visible;
}

.search-dropdown-item:hover .search-name, .search-dropdown-item:hover .search-id-label, .search-dropdown-item:hover .search-id {
  color: var(--secondary-blue-green);
}

@media only screen and (device-width <= 390px) {
  .search-dropdown-item > img {
    visibility: hidden;
    display: none;
  }

  .search-name, .search-id-label, .search-id {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .search-dropdown-item .search-name, .search-dropdown-item .search-id-label, .search-dropdown-item .search-id {
    font-size: 24px;
    line-height: 32px;
  }
}

.search-dropdown-recent {
  cursor: pointer;
  border-radius: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  padding: 16px 40px 16px 30px;
  display: flex;
}

.search-dropdown-recent img {
  visibility: hidden;
  margin-right: 12px;
}

.search-dropdown-recent .search-name {
  color: var(--primary-dark-2);
  justify-self: flex-start;
}

.search-dropdown-recent .search-recent {
  color: var(--primary-dark-3);
  margin-left: 10px;
}

.search-dropdown-recent .search-id-label {
  color: var(--primary-dark-3);
  margin-left: auto;
  margin-right: 15px;
}

.search-dropdown-recent .search-id {
  color: var(--primary-dark-3);
}

.invisible {
  visibility: hidden;
  display: none;
}

.search-dropdown-recent:hover {
  background: var(--secondary-green-hue);
}

.search-dropdown-recent:hover img {
  visibility: visible;
}

.search-dropdown-recent:hover .search-name, .search-dropdown-recent:hover .search-id-label, .search-dropdown-recent:hover .search-id {
  color: var(--secondary-blue-green);
}

@media only screen and (device-width <= 390px) {
  .search-dropdown-recent > img {
    visibility: hidden;
    display: none;
  }

  .search-name, .search-id-label, .search-id, .search-recent {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .search-dropdown-recent .search-name, .search-dropdown-recent .search-id-label, .search-dropdown-recent .search-id, .search-dropdown-recent .search-recent {
    font-size: 24px;
    line-height: 32px;
  }
}

.search-dropdown {
  background: var(--background-white);
  z-index: 10;
  border-radius: 0 0 20px 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 8px;
  width: 100%;
  padding: 16px 0 24px;
  display: flex;
  position: absolute;
  top: 82px;
  left: 0;
  box-shadow: 0 4px 10px #00000014;
}

.search-dropdown .search-dropdown-info {
  text-align: center;
  color: var(--primary-dark-2);
}

.search-dropdown-invisible {
  visibility: hidden;
  display: none;
}

@media only screen and (device-width <= 390px) {
  .search-dropdown {
    border-radius: 0 0 10px 10px;
    width: 108%;
    top: 55px;
    left: -4%;
    right: -4%;
    box-shadow: 2px 2px 4px #00000014;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .search-dropdown {
    top: 55px;
  }
}

.search {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.search .search-bar {
  border-bottom: 1px solid var(--background-divider);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  display: flex;
  position: relative;
}

.search .search-bar .search-icon {
  background-image: url("search.90786390.svg");
  width: 18px;
  height: 18px;
  margin: 12px;
  display: flex;
}

.search .search-bar .search-input {
  z-index: 10;
  opacity: 0;
  border: none;
  flex-grow: 1;
  margin-top: 18px;
  margin-bottom: 18px;
  display: flex;
  position: relative;
}

.search .search-bar .search-input:focus {
  outline: none;
}

.search .search-bar .search-input:disabled {
  background-color: var(--background-white);
}

.search .search-bar .search-input:placeholder-shown {
  opacity: 0;
}

.search .search-bar .search-input:focus, .search .search-bar .search-input:not(:disabled):active, .search .search-bar .search-input:not(:placeholder-shown) {
  opacity: 1;
}

.search .search-bar .placeholder-text {
  position: absolute;
  top: 19px;
  left: 44px;
}

.search .search-bar .placeholder-text .placeholder-first, .search .search-bar .placeholder-text .placeholder-search {
  color: var(--primary-dark-2);
  white-space: nowrap;
  position: relative;
}

.search .search-bar .placeholder-text .placeholder-second {
  color: var(--primary-dark-4);
  white-space: nowrap;
  position: relative;
}

.search .search-bar .placeholder-hidden {
  visibility: hidden;
  display: none;
}

.search .search-bar .clear-button {
  visibility: hidden;
}

.search .search-bar:focus-within {
  border-color: var(--primary-green);
}

.search .search-bar:focus-within .search-icon {
  background-image: url("search-focused.6aae80c4.svg");
}

.search .search-query-populated .clear-button {
  visibility: visible;
}

@media only screen and (device-width <= 390px) {
  .search > .search-bar {
    width: 90%;
  }

  .search > .search-bar .search-icon {
    margin: 0;
  }

  .search > .search-bar .search-input {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 24px;
    line-height: 32px;
  }

  .search > .search-bar .placeholder-text {
    align-items: center;
    display: flex;
    top: 12px;
    left: 30px;
  }

  .search > .search-bar .placeholder-text .placeholder-search {
    font-size: 22px;
    line-height: 32px;
  }

  .search > .search-bar .placeholder-text .placeholder-first, .search > .search-bar .placeholder-text .placeholder-second {
    visibility: hidden;
    display: none;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .search > .search-bar {
    width: 90%;
  }

  .search > .search-bar .search-input {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 24px;
    line-height: 32px;
  }

  .search > .search-bar .placeholder-text {
    align-items: center;
    display: flex;
    top: 12px;
    left: 52px;
  }

  .search > .search-bar .placeholder-text .placeholder-first, .search > .search-bar .placeholder-text .placeholder-search {
    font-size: 24px;
    line-height: 32px;
  }

  .search > .search-bar .placeholder-text .placeholder-second {
    visibility: hidden;
    display: none;
  }
}

@media only screen and (device-width <= 980px) {
  .search > .search-bar > .placeholder-text > .placeholder-second {
    visibility: hidden;
    display: none;
  }
}

@media only screen and (device-width <= 480px) {
  .search > .search-bar > .placeholder-text > .placeholder-first, .placeholder-second {
    visibility: hidden;
    display: none;
  }
}

.admin {
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  height: 100%;
  display: flex;
}

.admin .app-body {
  flex-direction: column;
  flex: auto;
  align-items: center;
  display: flex;
}

.admin .app-body .content-row-wrapper {
  background: var(--background-gray);
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 50px;
  display: flex;
}

.admin .app-body .content-row-wrapper .admin-content {
  flex-direction: column;
  gap: 10px;
  width: 80%;
  max-width: 1000px;
  margin-bottom: 20px;
  display: flex;
}

.admin .app-body .content-row-wrapper .admin-content .invite-button {
  align-self: flex-start;
  margin-bottom: 20px;
}

.admin .app-body .content-row-wrapper .admin-content .admin-overflow-message {
  text-align: center;
  color: var(--primary-dark-2);
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .admin > .app-body > .content-row-wrapper .admin-content {
    width: 90%;
  }
}

@media only screen and (device-width <= 391px) {
  .admin > .app-body > .content-row-wrapper .admin-content {
    width: 95%;
  }
}

.card-badge {
  border: 1px solid;
  border-radius: 160px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: flex;
}

.card-badge .badge-icon {
  background-size: 12px 12px;
  min-width: 12px;
  min-height: 12px;
}

.card-badge .label-xsmall-allcaps {
  white-space: nowrap;
}

.new-client {
  mix-blend-mode: multiply;
  background: linear-gradient(0deg, #79b60f2b 2.08%, #c7df9d26 100%);
  border-color: #d4e4b8;
}

.new-client .badge-icon {
  background-image: url("badge-new-client.e9a4ce5c.svg");
}

.archive {
  mix-blend-mode: multiply;
  background: #6866720d;
  border-color: #908f97;
}

.archive .badge-icon {
  background-image: url("badge-archive.bdf32cd3.svg");
}

.calibration-overdue {
  mix-blend-mode: multiply;
  background: linear-gradient(90deg, #ffc95833 0%, #fff8e933 100%), #fff;
  border-color: #f5e2bb;
}

.calibration-overdue .badge-icon {
  background-image: url("badge-alert.126cc116.svg");
}

.alert-device {
  mix-blend-mode: multiply;
  background: linear-gradient(90deg, #ffc95833 0%, #fff8e933 100%), #fff;
  border-color: #f5e2bb;
}

.alert-device .badge-icon {
  background-image: url("badge-alert.126cc116.svg");
}

.recent-violation {
  mix-blend-mode: multiply;
  background: linear-gradient(#f0a3a31a 0%, #d600001a 100%);
  border-color: #ecbcbc;
}

.recent-violation .badge-icon {
  background-image: url("badge-violation.6c3ec34b.svg");
}

.download-button {
  background: var(--primary-dark-1);
  cursor: pointer;
  border: none;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 42px;
  min-height: 42px;
  display: flex;
}

.download-button:hover {
  background: var(--primary-dark-2);
}

.inverted {
  background: var(--background-gray);
}

.inverted img {
  filter: invert();
}

.inverted:hover {
  background: var(--primary-dark-4);
}

.download-button-disabled {
  cursor: default;
}

.download-button-disabled img {
  filter: invert(40%);
}

.download-button-disabled:hover {
  background: var(--background-gray);
  pointer-events: none;
  cursor: default;
}

.document-card {
  background: var(--background-white);
  border: 1px solid var(--background-divider);
  border-radius: 14px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 20px;
  display: flex;
}

.document-card .document-text {
  flex-direction: column;
  margin-right: 8px;
  display: flex;
}

.document-card .document-text .customer-badges {
  flex-flow: wrap;
  gap: 8px;
  margin-bottom: .5rem;
  display: flex;
}

.document-card .document-text .document-summary {
  color: var(--primary-dark-2);
  margin-bottom: 2px;
}

.document-card .document-text .document-date {
  color: var(--primary-dark-2);
  margin-bottom: 8px;
}

.document-card .document-text .document-type {
  color: var(--primary-dark-3);
}

.document-card .download-button {
  margin-left: auto;
}

.card-customer {
  background-color: var(--background-white);
  border: 1px solid var(--background-white);
  cursor: pointer;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 28px 40px 40px;
  display: flex;
}

.card-customer:not(:last-child) {
  margin-bottom: .825rem;
}

.card-customer .card-customer-text {
  border-radius: 8px;
  flex-direction: column;
  flex-grow: .8;
  display: flex;
}

.card-customer .card-customer-text .customer-badges {
  flex-flow: wrap;
  gap: 8px;
  display: flex;
}

.card-customer .card-customer-text .customer-name {
  color: var(--primary-dark-1);
  margin-top: 20px;
}

.card-customer .card-customer-text .customer-id {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.card-customer .card-customer-text .customer-id .id-label, .card-customer .card-customer-text .customer-id .id-number {
  color: var(--primary-dark-2);
}

.card-customer .document-card {
  visibility: hidden;
  margin-right: 40px;
  display: flex;
}

.card-customer:hover {
  box-shadow: 2px 2px 4px #00000014;
}

.card-customer:hover .document-card {
  visibility: inherit;
}

.new-client-card:hover {
  background: linear-gradient(88.5deg, #95bd7633 .74%, #bad2a733 51.39%, #cdddbf33 90.64%, #f4f4f433 120.68%), #fff;
}

.recent-violation-card:hover {
  background: linear-gradient(#f0a3a31a 0%, #d600001a 100%);
}

.alert-device-card:hover, .calibration-overdue-card:hover, .archive-card:hover {
  background: linear-gradient(90deg, #ffc95833 0%, #fff8e933 100%), #fff;
}

@media only screen and (device-width <= 744px) {
  .card-customer {
    border-radius: 20px;
    flex-direction: column;
    margin-left: 21px;
    margin-right: 21px;
    padding: 28px 22px 20px;
    overflow: hidden;
    box-shadow: 2px 2px 4px #00000014;
  }

  .card-customer .card-customer-text {
    align-self: flex-start;
  }

  .card-customer .document-card {
    visibility: hidden;
    align-self: center;
    margin: 0;
    display: none;
  }

  .card-customer:hover > .document-card {
    visibility: inherit;
    margin-top: 20px;
    display: flex;
  }

  .new-client-card {
    background: linear-gradient(88.5deg, #95bd7633 .74%, #bad2a733 51.39%, #cdddbf33 90.64%, #f4f4f433 120.68%), #fff;
  }

  .recent-violation-card {
    background: linear-gradient(#f0a3a31a 0%, #d600001a 100%);
  }

  .alert-device-card, .calibration-overdue-card, .archive-card {
    background: linear-gradient(90deg, #ffc95833 0%, #fff8e933 100%), #fff;
  }

  .no-badge-card {
    background: linear-gradient(90deg, #2b93ff33 0%, #e4f1ff33 100%), #fff;
  }
}

.checkbox-text {
  border: 1px solid;
  border-color: var(--primary-dark-4);
  color: var(--primary-dark-2);
  cursor: pointer;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  min-width: 109px;
  padding: 8px 12px;
  display: flex;
  position: relative;
}

.checkbox-text .checkbox-check {
  border: 1px solid;
  border-color: var(--primary-dark-4);
  border-radius: 2.83333px;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  display: flex;
}

.checkbox-text .checkbox-check img, .checkbox-text .checkbox {
  visibility: hidden;
  display: none;
}

.checkbox-text-checked {
  color: var(--primary-dark-2);
}

.checkbox-text-checked .checkbox-check > img {
  visibility: inherit;
  display: inline;
}

@media only screen and (device-width <= 744px) {
  .checkbox-text {
    margin-right: 21px;
  }
}

.quick-filter {
  background: var(--background-gray);
  cursor: auto;
  border: none;
  border-radius: 160px;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 16px;
  display: flex;
}

.quick-filter .body-standard-regular {
  color: var(--primary-dark-3);
  margin-right: 4px;
}

.quick-filter .quick-filter-count {
  background-image: url("ellipse-1.2dd60cbc.svg");
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 25px;
  display: flex;
}

.quick-filter .quick-filter-count .label-xsmall {
  color: var(--primary-dark-2);
}

.quick-filter .checkbox {
  visibility: hidden;
  display: none;
}

.quick-filter-enabled {
  cursor: pointer;
}

.quick-filter-enabled .body-standard-regular {
  color: var(--primary-dark-2);
}

.quick-filter-enabled .quick-filter-count > .label-xsmall {
  color: var(--primary-dark-1);
}

.quick-filter-enabled:hover {
  background: var(--background-divider);
}

.quick-filter-enabled:hover .body-standard-regular {
  color: var(--primary-dark-2);
}

.quick-filter-selected {
  background: var(--primary-dark-1);
}

.quick-filter-selected .body-standard-regular {
  color: var(--background-white);
}

.quick-filter-selected .quick-filter-count {
  background-image: url("ellipse-2.b74c8701.svg");
}

.quick-filter-selected:hover {
  background: var(--primary-dark-1);
}

.quick-filter-selected:hover .body-standard-regular {
  color: var(--background-white);
}

@media only screen and (device-width <= 390px) {
  .quick-filter {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .quick-filter {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

.sort-filter {
  flex-direction: row;
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 42px;
  display: flex;
}

.sort-filter .sort-filter-wrapper {
  flex-flow: wrap;
  justify-content: flex-start;
  gap: 12px;
  width: 80%;
  display: flex;
}

.sort-filter .sort-filter-wrapper .sort-button {
  background-color: var(--primary-dark-5);
  margin-left: auto;
  display: flex;
}

@media only screen and (device-width <= 390px) {
  .sort-filter {
    gap: 4px;
    margin: 20px 0 20px 15px;
  }

  .sort-filter .sort-filter-wrapper {
    gap: 4px;
    width: 95%;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .sort-filter {
    gap: 6px;
    width: 95%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .sort-filter .sort-filter-wrapper {
    width: 95%;
  }
}

.affiliate-attorney {
  background-color: var(--background-white);
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.affiliate-attorney .tab-container {
  border-bottom: 1px solid var(--primary-dark-4);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  transition: all .5s ease-in-out;
  display: flex;
}

.affiliate-attorney .tab-container .tab {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  width: 80%;
  display: flex;
}

.affiliate-attorney .tab-container .tab .tab-button {
  border: 1px solid var(--primary-dark-4);
  background-color: var(--background-white);
  color: var(--primary-dark-1);
  cursor: pointer;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  padding: 10px 20px;
  font-family: Roboto;
  font-size: 36px;
  transition: background-color .3s;
  box-shadow: 2px 2px 4px #00000014;
}

.affiliate-attorney .tab-container .tab .tab-button:hover {
  color: var(--primary-dark-1);
  background: linear-gradient(90deg, #2b93ff33 0%, #e4f1ff33 100%), #fff;
}

.affiliate-attorney .tab-container .tab .tab-button.active {
  color: var(--background-white);
  background-color: var(--primary-dark-1);
  border: 1px solid #0000;
}

.affiliate-attorney .tab-container .tab .tab-button.active:hover {
  background-color: var(--primary-dark-1);
}

.affiliate-attorney .content-wrapper {
  background: var(--background-gray);
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 50px;
  display: flex;
}

.affiliate-attorney .content-wrapper .attorney-content {
  flex-direction: column;
  gap: 10px;
  width: 75%;
  max-width: 1500px;
  margin-bottom: 20px;
  display: flex;
}

.affiliate-attorney .content-wrapper .attorney-content .attorney-view-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.affiliate-attorney .content-wrapper .attorney-content .attorney-view-header .btn-wrapper {
  align-items: center;
  gap: .75rem;
  display: flex;
}

@media only screen and (width <= 744px) {
  .affiliate-attorney > .content-wrapper > .attorney-content {
    width: 95%;
  }

  .affiliate-attorney > .tab-container > .tab > .tab-button {
    font-size: 22px;
  }
}

.employee-sort-filter {
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 25px auto;
  display: flex;
}

.employee-sort-filter .employee-filters-sort {
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
  display: flex;
}

.employee-sort-filter .employee-filters-sort .sort-button {
  background-color: var(--primary-dark-5);
  margin-left: auto;
  display: flex;
}

@media only screen and (device-width <= 390px) {
  .employee-sort-filter {
    width: 90%;
  }

  .employee-sort-filter .employee-filters-sort .sort-button {
    margin-right: 0;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .employee-sort-filter {
    width: 90%;
  }
}

.card-employee {
  background-color: var(--background-white);
  border: 1px solid var(--background-white);
  cursor: default;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 28px 40px 40px;
  display: flex;
}

.card-employee:not(:last-child) {
  margin-bottom: .825rem;
}

.card-employee .card-employee-text {
  border-radius: 8px;
  flex-direction: column;
  flex-grow: .8;
  display: flex;
}

.card-employee .card-employee-text .no-record-found {
  color: gray;
  text-align: center;
}

.card-employee .card-employee-text .employee-name {
  color: var(--primary-dark-1);
  margin-top: 20px;
}

.card-employee .card-employee-text .employee-id {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.card-employee .card-employee-text .employee-id .id-label, .card-employee .card-employee-text .employee-id .id-number {
  color: var(--primary-dark-2);
}

.card-employee .document-card {
  visibility: hidden;
  margin-right: 40px;
  display: flex;
}

.card-employee:hover {
  box-shadow: 2px 2px 4px #00000014;
}

.no-badge-card:hover {
  background: linear-gradient(90deg, #2b93ff33 0%, #e4f1ff33 100%), #fff;
}

@media only screen and (device-width <= 744px) {
  .card-employee {
    border-radius: 20px;
    flex-direction: column;
    margin-left: 21px;
    margin-right: 21px;
    padding: 28px 22px 20px;
    overflow: hidden;
    box-shadow: 2px 2px 4px #00000014;
  }

  .card-employee .card-employee-text {
    align-self: flex-start;
  }

  .card-employee:hover > .document-card {
    visibility: inherit;
    margin-top: 20px;
    display: flex;
  }

  .no-badge-card {
    background: linear-gradient(90deg, #2b93ff33 0%, #e4f1ff33 100%), #fff;
  }
}

.general-search {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.general-search .general-search-bar {
  border-bottom: 1px solid var(--background-divider);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  display: flex;
  position: relative;
}

.general-search .general-search-bar .general-search-icon {
  background-image: url("search.90786390.svg");
  width: 18px;
  height: 18px;
  margin: 12px;
  display: flex;
}

.general-search .general-search-bar .general-search-input {
  z-index: 10;
  opacity: 0;
  border: none;
  flex-grow: 1;
  margin-top: 18px;
  margin-bottom: 18px;
  display: flex;
  position: relative;
}

.general-search .general-search-bar .general-search-input:focus {
  outline: none;
}

.general-search .general-search-bar .general-search-input:disabled {
  background-color: var(--background-white);
}

.general-search .general-search-bar .general-search-input:placeholder-shown {
  opacity: 0;
}

.general-search .general-search-bar .general-search-input:focus, .general-search .general-search-bar .general-search-input:not(:disabled):active, .general-search .general-search-bar .general-search-input:not(:placeholder-shown) {
  opacity: 1;
}

.general-search .general-search-bar .placeholder-text {
  position: absolute;
  top: 19px;
  left: 44px;
}

.general-search .general-search-bar .placeholder-text .placeholder-search {
  visibility: visible;
  display: inline-block;
}

.general-search .general-search-bar .placeholder-text .placeholder-first, .general-search .general-search-bar .placeholder-text .placeholder-search {
  color: var(--primary-dark-2);
  white-space: nowrap;
  position: relative;
}

.general-search .general-search-bar .placeholder-text .placeholder-second {
  color: var(--primary-dark-4);
  white-space: nowrap;
  position: relative;
}

.general-search .general-search-bar .placeholder-hidden {
  visibility: hidden;
  display: none;
}

.general-search .general-search-bar .clear-button {
  visibility: hidden;
}

.general-search .general-search-bar:focus-within {
  border-color: var(--primary-green);
}

.general-search .general-search-bar:focus-within .general-search-icon {
  background-image: url("search-focused.6aae80c4.svg");
}

.general-search .general-search-query-populated .clear-button {
  visibility: visible;
}

@media only screen and (device-width <= 390px) {
  .general-search > .general-search-bar {
    width: 90%;
  }

  .general-search > .general-search-bar .general-search-icon {
    margin: 0;
  }

  .general-search > .general-search-bar .general-search-input {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 24px;
    line-height: 32px;
  }

  .general-search > .general-search-bar .placeholder-text {
    align-items: center;
    display: flex;
    top: 12px;
    left: 30px;
  }

  .general-search > .general-search-bar .placeholder-text .placeholder-search {
    font-size: 22px;
    line-height: 32px;
  }

  .general-search > .general-search-bar .placeholder-text .placeholder-first, .general-search > .general-search-bar .placeholder-text .placeholder-second {
    visibility: hidden;
    display: none;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .general-search > .general-search-bar {
    width: 90%;
  }

  .general-search > .general-search-bar .general-search-input {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 24px;
    line-height: 32px;
  }

  .general-search > .general-search-bar .placeholder-text {
    align-items: center;
    display: flex;
    top: 12px;
    left: 52px;
  }

  .general-search > .general-search-bar .placeholder-text .placeholder-first, .general-search > .general-search-bar .placeholder-text .placeholder-search {
    font-size: 24px;
    line-height: 32px;
  }

  .general-search > .general-search-bar .placeholder-text .placeholder-second {
    visibility: hidden;
    display: none;
  }
}

@media only screen and (device-width <= 980px) {
  .general-search > .general-search-bar > .placeholder-text > .placeholder-second {
    visibility: hidden;
    display: none;
  }
}

@media only screen and (device-width <= 480px) {
  .general-search > .general-search-bar > .placeholder-text > .placeholder-first, .placeholder-second {
    visibility: hidden;
    display: none;
  }
}

.unverified-view {
  background-color: var(--background-white);
  flex-direction: column;
  align-items: stretch;
  margin: 66px 10px 138px;
  display: flex;
}

.unverified-view .content-wrapper {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.unverified-view .content-wrapper .unverified-content {
  background: var(--background-gray);
  border-radius: 10px;
  flex-direction: column;
  flex-grow: .4;
  align-items: center;
  padding-bottom: 60px;
  display: flex;
}

.unverified-view .content-wrapper .unverified-content .in-progress {
  margin-top: 60px;
}

.unverified-view .content-wrapper .unverified-content .large-type {
  text-align: center;
  max-width: 541px;
  margin-top: 32px;
  display: flex;
}

.unverified-view .content-wrapper .unverified-content .body-large-light {
  text-align: center;
  max-width: 471px;
  margin-top: 8px;
  display: flex;
}

.unverified-view .content-wrapper .unverified-content .primary-button {
  margin-top: 32px;
}

@media only screen and (device-width <= 390px) {
  .unverified-view {
    margin-top: 25px;
  }

  .unverified-view .content-wrapper > .unverified-content .large-type {
    text-align: center;
    opacity: .8;
    max-width: 280px;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
  }

  .unverified-view .content-wrapper > .unverified-content .body-large-light {
    width: 325px;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .unverified-view > .content-wrapper > .unverified-content > .large-type {
    width: 500px;
  }
}

.impersonate-header {
  background: var(--primary-green);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 8px 10px;
  display: flex;
}

.impersonate-header .impersonate-header-text {
  cursor: pointer;
  color: var(--background-white);
  text-align: center;
  display: flex;
}

@media only screen and (device-width <= 390px) {
  .impersonate-header {
    padding: 12px 10px;
  }
}

.affiliate {
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  min-height: 100vh;
  display: flex;
}

.affiliate .app-body {
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  display: flex;
}

.copy-button {
  background: var(--primary-green);
  border: 3px solid var(--background-white);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 81px;
  padding: 12px 16px;
  display: flex;
}

.copied {
  background: var(--secondary-blue-green);
}

.contact-button {
  background-color: var(--primary-dark-5);
  cursor: pointer;
  border: none;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 42px;
  min-height: 42px;
  display: flex;
  position: relative;
}

.contact-button .contact-button-icon {
  background-image: url("contact.5ea161be.svg");
  width: 20px;
  height: 20px;
}

.contact-button input {
  visibility: hidden;
  display: none;
}

.contact-button .contact-button-dropdown {
  background: var(--background-white);
  border: 1px solid;
  border-color: var(--background-divider);
  z-index: 1;
  cursor: auto;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 4px 4px 4px 8px;
  display: flex;
  position: absolute;
  top: 48px;
  left: 0;
  box-shadow: 0 0 150px #00000026;
}

.contact-button .contact-button-dropdown .contact-button-dropdown-number {
  white-space: nowrap;
  justify-content: flex-start;
  display: flex;
}

.contact-button:hover {
  background: var(--primary-dark-2);
}

.contact-checked, .contact-checked:hover {
  background: #c45e06;
}

.contact-disabled:hover {
  background: var(--background-divider);
}

.contact-disabled {
  border: 1px solid;
  border-color: var(--primary-dark-3);
  background: var(--background-divider);
  cursor: auto;
}

.contact-disabled .contact-button-icon {
  background-image: url("contact-disabled.a609cb5e.svg");
}

.deactivate-affiliate-button {
  background: var(--background-divider);
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
}

.deactivate-affiliate-button:hover {
  color: var(--primary-dark-2);
}

.deactivate-affiliate-button:active {
  background: var(--primary-dark-1);
  color: var(--primary-dark-4);
}

.deactivate-affiliate-disabled {
  background: var(--background-divider);
  color: var(--primary-dark-2);
  cursor: auto;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
}

.deactivate-affiliate-disabled:hover {
  color: var(--primary-dark-2);
}

.deactivate-affiliate-disabled:active {
  background: var(--background-divider);
  color: var(--primary-dark-2);
}

.email-button {
  background-color: var(--primary-dark-5);
  cursor: pointer;
  border: none;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 42px;
  min-height: 42px;
  display: flex;
  position: relative;
}

.email-button .email-button-icon {
  background-image: url("email.e8e56068.svg");
  width: 20px;
  height: 16px;
}

.email-button input {
  visibility: hidden;
  display: none;
}

.email-button .email-button-dropdown {
  background: var(--background-white);
  border: 1px solid;
  border-color: var(--background-divider);
  z-index: 1;
  cursor: auto;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 4px 4px 4px 8px;
  display: flex;
  position: absolute;
  top: 48px;
  left: -100px;
  box-shadow: 0 0 150px #00000026;
}

.email-button .email-button-dropdown .email-button-dropdown-email {
  white-space: nowrap;
  justify-content: flex-start;
  display: flex;
}

.email-button:hover {
  background: var(--primary-dark-2);
}

.email-checked, .email-checked:hover {
  background: #c45e06;
}

.email-disabled:hover {
  background: var(--background-divider);
}

.email-disabled {
  border: 1px solid;
  border-color: var(--primary-dark-3);
  background: var(--background-divider);
  cursor: auto;
}

.email-disabled .email-button-icon {
  background-image: url("email-disabled.4a0b2bc9.svg");
}

.location-button {
  background-color: var(--primary-dark-5);
  cursor: pointer;
  border: none;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 42px;
  min-height: 42px;
  display: flex;
  position: relative;
}

.location-button .location-button-icon {
  background-image: url("location.be08d611.svg");
  width: 18px;
  height: 22px;
}

.location-button input {
  visibility: hidden;
  display: none;
}

.location-button .location-button-dropdown {
  background: var(--background-white);
  border: 1px solid;
  border-color: var(--background-divider);
  z-index: 1;
  cursor: auto;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 4px 4px 4px 8px;
  display: flex;
  position: absolute;
  top: 48px;
  left: -50px;
  box-shadow: 0 0 150px #00000026;
}

.location-button .location-button-dropdown .location-button-dropdown-address-wrapper {
  flex-direction: column;
  display: flex;
}

.location-button .location-button-dropdown .location-button-dropdown-address-wrapper .location-button-dropdown-address {
  justify-content: flex-start;
  display: flex;
}

.location-button:hover {
  background: var(--primary-dark-2);
}

.location-checked, .location-checked:hover {
  background: #c45e06;
}

.location-disabled:hover {
  background: var(--background-divider);
}

.location-disabled {
  border: 1px solid;
  border-color: var(--primary-dark-3);
  background: var(--background-divider);
  cursor: auto;
}

.location-disabled .location-button-icon {
  background-image: url("location-disabled.553ae67c.svg");
}

.secondary-button {
  border: 1px solid var(--primary-dark-4);
  color: var(--primary-dark-2);
  cursor: pointer;
  background: none;
  border-radius: 160px;
  padding: 12px 24px;
}

.secondary-button:active {
  background: var(--primary-dark-4);
}

.modal {
  z-index: 11;
  background-color: #00000080;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.modal .modal-wrapper {
  background: var(--background-white);
  z-index: 2;
  border-radius: 20px;
  flex-direction: column;
  gap: 40px;
  max-width: 365px;
  margin: auto;
  padding: 40px;
  display: flex;
  position: relative;
  box-shadow: 0 0 150px #00000026;
}

.modal .modal-wrapper .modal-nav-wrapper {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.modal .modal-wrapper .modal-nav-wrapper .body-standard-semibold {
  color: var(--primary-green);
}

.modal .modal-wrapper .modal-buttons-wrapper {
  flex-direction: row;
  justify-content: stretch;
  align-self: stretch;
  align-items: center;
  gap: 5px;
  display: flex;
}

.modal .modal-wrapper .modal-buttons-wrapper .secondary-button {
  flex-grow: 1;
  padding-top: 16px;
  padding-bottom: 16px;
}

.modal .modal-wrapper .modal-buttons-wrapper .primary-button {
  flex-grow: 1;
}

.modal-hidden {
  visibility: hidden;
  display: none;
}

@media only screen and (device-width <= 744px) {
  .modal > .modal-wrapper {
    gap: 32px;
    padding: 32px;
  }
}

.affiliate-details-header {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  display: flex;
}

.affiliate-details-header .affiliate-details-text {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.affiliate-details-header .affiliate-details-text .affiliate-type {
  color: var(--primary-dark-2);
  margin-bottom: 20px;
}

.affiliate-details-header .affiliate-details-text .affiliate-details-unverified-label {
  color: #995900;
  margin-bottom: 20px;
  margin-right: 8px;
}

.affiliate-details-header .affiliate-details-text .affiliate-details-unverified-label img {
  margin-right: 5px;
}

.affiliate-details-header .affiliate-details-text .affiliate-name {
  color: var(--primary-dark-1);
}

.affiliate-details-header .affiliate-details-text .affiliate-firm {
  color: var(--primary-dark-2);
  margin-top: 8px;
}

.affiliate-details-header .affiliate-details-text .affiliate-location-wrapper {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  display: flex;
}

.affiliate-details-header .affiliate-details-text .affiliate-location-wrapper .affiliate-firm-city {
  color: var(--primary-dark-2);
}

.affiliate-details-header .affiliate-details-text .affiliate-location-wrapper img {
  height: 2px;
}

.affiliate-details-header .affiliate-details-text .affiliate-location-wrapper .affiliate-firm-state {
  color: var(--primary-dark-2);
}

.affiliate-details-header .affiliate-details-interactive {
  flex-direction: column;
  align-items: flex-end;
  margin-left: 20px;
  display: flex;
}

.affiliate-details-header .affiliate-details-interactive .affiliate-details-contact {
  flex-direction: row;
  gap: 8px;
  display: flex;
}

.affiliate-details-header .affiliate-details-interactive .affiliate-details-buttons {
  flex-direction: column;
  gap: 16px;
  margin-top: 36px;
  display: flex;
}

@media only screen and (device-width <= 744px) {
  .affiliate-details-header {
    flex-direction: column;
  }

  .affiliate-details-header .affiliate-details-interactive {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    margin-left: 0;
  }

  .affiliate-details-header .affiliate-details-interactive .affiliate-details-contact {
    margin-right: 8px;
  }
}

.back-button {
  color: var(--primary-dark-1);
  background: var(--background-gray);
  cursor: pointer;
  border: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.back-button .back-arrow {
  background-image: url("back-arrow.939b1895.svg");
  width: 14px;
  height: 10px;
  margin-right: 7px;
}

.back-button:hover {
  color: var(--primary-dark-2);
}

.back-button:hover .back-arrow {
  background-image: url("back-arrow-hover.1fd1fd06.svg");
}

.affiliate-details {
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  height: 100%;
  display: flex;
}

.affiliate-details .app-body {
  background: var(--background-gray);
  flex-direction: column;
  flex: 2 auto;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
  display: flex;
}

.affiliate-details .app-body .back-button {
  align-self: flex-start;
  margin-top: 24px;
  margin-left: 3%;
}

.affiliate-details .app-body .affiliate-details-wrapper-row {
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column {
  background-color: var(--background-white);
  border: 1px solid var(--background-white);
  border-radius: 10px;
  flex-direction: column;
  flex-grow: .3;
  justify-content: stretch;
  align-items: stretch;
  margin-bottom: 40px;
  padding: 40px 60px 45px;
  display: flex;
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column .tab-container {
  border-bottom: 1px solid var(--primary-dark-4);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  transition: all .5s ease-in-out;
  display: flex;
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column .tab-container .tab {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  width: 80%;
  display: flex;
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column .tab-container .tab .tab-button {
  border: 1px solid var(--primary-dark-4);
  background-color: var(--background-white);
  color: var(--primary-dark-1);
  cursor: pointer;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  padding: 10px 20px;
  font-family: Roboto;
  font-size: 26px;
  transition: background-color .3s;
  box-shadow: 2px 2px 4px #00000014;
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column .tab-container .tab .tab-button:hover {
  color: var(--primary-dark-1);
  background: linear-gradient(90deg, #2b93ff33 0%, #e4f1ff33 100%), #fff;
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column .tab-container .tab .tab-button.active {
  color: var(--background-white);
  background-color: var(--primary-dark-1);
  border: 1px solid #0000;
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column .tab-container .tab .tab-button.active:hover {
  background-color: var(--primary-dark-1);
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column .client-list-label {
  color: var(--primary-dark-2);
  margin-top: 30px;
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column .affiliate-customer-item {
  border-top: 1px solid var(--background-divider);
  margin-top: 20px;
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column .affiliate-customer-item .affiliate-customer-item-id {
  color: var(--primary-dark-2);
  margin-top: 20px;
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column .affiliate-customer-item .affiliate-customer-item-name {
  margin-top: 8px;
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column .affiliate-details-unverified-background {
  background-color: var(--background-divider);
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 319px;
  margin-top: 23px;
  display: flex;
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column .affiliate-details-unverified-background img {
  margin-bottom: 32px;
}

.affiliate-details .app-body .affiliate-details-wrapper-row .affiliate-details-wrapper-column .affiliate-details-unverified-background .affiliate-details-unverified-message {
  color: var(--primary-dark-2);
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

@media only screen and (device-width <= 391px) {
  .affiliate-details > .app-body > .affiliate-details-wrapper-row > .affiliate-details-wrapper-column {
    padding: 20px 30px 25px;
  }
}

.filter-dropdown-item {
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 4px;
  min-height: 20px;
  padding: 14px 8px;
  display: flex;
  overflow-x: hidden;
}

.filter-highlighted {
  background: var(--secondary-green-hue);
}

.filter-not-highlighted:hover {
  background: var(--background-divider);
}

.jurisdiction-dropdown {
  cursor: pointer;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 8px 0 8px 16px;
  display: flex;
  position: relative;
}

.jurisdiction-dropdown input {
  visibility: hidden;
  display: none;
}

.jurisdiction-dropdown .jurisdiction-dropdown-icon {
  filter: invert();
  background-image: url("dropdown-arrow-down.744e14f1.svg");
  width: 8px;
  height: 5px;
}

.jurisdiction-dropdown .jurisdiction-dropdown-dropdown {
  background: var(--background-white);
  z-index: 1;
  cursor: auto;
  border-radius: 10px;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
  padding: 16px 12px;
  display: flex;
  position: absolute;
  top: 30px;
  right: 0;
  box-shadow: 2px 2px 30px #0000000d;
}

.jurisdiction-dropdown-checked .jurisdiction-dropdown-icon {
  background-image: url("dropdown-arrow-up.7f293f31.svg");
}

.customer-details-header {
  flex-direction: row;
  justify-content: space-between;
  margin-top: 58px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
}

.customer-details-header .customer-details-text {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}

.customer-details-header .customer-details-text .customer-badges {
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 20px;
  display: flex;
}

.customer-details-header .customer-details-text .customer-name {
  color: var(--primary-dark-1);
}

.customer-details-header .customer-details-text .customer-id {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.customer-details-header .customer-details-text .customer-id .id-label, .customer-details-header .customer-details-text .customer-id .id-number {
  color: var(--primary-dark-2);
}

.customer-details-header .customer-details-interactive {
  flex-direction: column;
  align-items: flex-end;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}

.customer-details-header .customer-details-interactive .customer-details-contact {
  flex-direction: row;
  gap: 8px;
  display: flex;
}

.customer-details-header .customer-details-interactive .state-jurisdiction-wrapper {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.customer-details-header .customer-details-interactive .state-jurisdiction-wrapper .state-jurisdiction-label {
  white-space: nowrap;
  margin-top: 20px;
  margin-left: 5px;
}

@media only screen and (device-width <= 744px) {
  .customer-details-header {
    flex-direction: column;
  }

  .customer-details-header .customer-details-interactive {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }

  .customer-details-header .customer-details-interactive .state-jurisdiction-wrapper {
    margin-right: 20px;
  }
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-caption-font-size: 18px;
  --rdp-accent-color: #00f;
  --rdp-background-color: #e7edff;
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  margin: 1em;
}

.rdp-vhidden {
  box-sizing: border-box;
  appearance: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.rdp-button_reset {
  appearance: none;
  cursor: default;
  color: inherit;
  font: inherit;
  background: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.rdp-button_reset:focus-visible {
  outline: none;
}

.rdp-button {
  border: 2px solid #0000;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
  opacity: .25;
}

.rdp-button:not([disabled]) {
  cursor: pointer;
}

.rdp-button:focus-visible:not([disabled]) {
  color: inherit;
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
}

.rdp-months {
  display: flex;
}

.rdp-month {
  margin: 0 1em;
}

.rdp-month:first-child {
  margin-left: 0;
}

.rdp-month:last-child {
  margin-right: 0;
}

.rdp-table {
  max-width: calc(var(--rdp-cell-size) * 7);
  border-collapse: collapse;
  margin: 0;
}

.rdp-with_weeknumber .rdp-table {
  max-width: calc(var(--rdp-cell-size) * 8);
  border-collapse: collapse;
}

.rdp-caption {
  text-align: left;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: flex;
}

.rdp-multiple_months .rdp-caption {
  text-align: center;
  display: block;
  position: relative;
}

.rdp-caption_dropdowns {
  display: inline-flex;
  position: relative;
}

.rdp-caption_label {
  z-index: 1;
  white-space: nowrap;
  color: currentColor;
  font-family: inherit;
  font-size: var(--rdp-caption-font-size);
  border: 2px solid #0000;
  align-items: center;
  margin: 0;
  padding: 0 .25em;
  font-weight: bold;
  display: inline-flex;
  position: relative;
}

.rdp-nav {
  white-space: nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.rdp-nav_button {
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: .25em;
  display: inline-flex;
}

.rdp-dropdown_year, .rdp-dropdown_month {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.rdp-dropdown {
  appearance: none;
  z-index: 2;
  width: 100%;
  cursor: inherit;
  opacity: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: #0000;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.rdp-dropdown[disabled] {
  opacity: unset;
  color: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
  border-radius: 6px;
}

.rdp-dropdown_icon {
  margin: 0 0 0 5px;
}

.rdp-head {
  border: 0;
}

.rdp-head_row, .rdp-row {
  height: 100%;
}

.rdp-head_cell {
  vertical-align: middle;
  text-align: center;
  height: 100%;
  height: var(--rdp-cell-size);
  text-transform: uppercase;
  padding: 0;
  font-size: .75em;
  font-weight: 700;
}

.rdp-tbody {
  border: 0;
}

.rdp-tfoot {
  margin: .5em;
}

.rdp-cell {
  width: var(--rdp-cell-size);
  height: 100%;
  height: var(--rdp-cell-size);
  text-align: center;
  padding: 0;
}

.rdp-weeknumber {
  font-size: .75em;
}

.rdp-weeknumber, .rdp-day {
  box-sizing: border-box;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  border: 2px solid #0000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  overflow: hidden;
}

.rdp-day_today:not(.rdp-day_outside) {
  font-weight: bold;
}

.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover {
  color: #fff;
  opacity: 1;
  background-color: var(--rdp-accent-color);
}

.rdp-day_outside {
  opacity: .5;
}

.rdp-day_selected:focus-visible {
  outline: var(--rdp-outline);
  outline-offset: 2px;
  z-index: 1;
}

.rdp:not([dir="rtl"]) .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp:not([dir="rtl"]) .rdp-day_range_end:not(.rdp-day_range_start), .rdp[dir="rtl"] .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir="rtl"] .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp-day_range_end.rdp-day_range_start {
  border-radius: 100%;
}

.rdp-day_range_middle {
  border-radius: 0;
}

.calendar {
  background: var(--background-white);
  border: 1px solid var(--background-divider);
  cursor: pointer;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  display: flex;
  position: relative;
}

.calendar .calendar-date {
  border-right: 1px solid var(--background-divider);
  margin-right: 8px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 6px;
}

.calendar .calendar-icon {
  background-image: url("calendar.c6317b93.svg");
  width: 20px;
  height: 20px;
}

.calendar-active .calendar-icon {
  background-image: url("calendar-focused.1222f23d.svg");
  width: 20px;
}

.hidden-day-picker {
  visibility: hidden;
  display: none;
}

.rdp {
  background: var(--background-white);
  --rdp-cell-size: 44px;
  cursor: auto;
  z-index: 10;
  border-radius: 12.9776px;
  padding: 20px;
  position: absolute;
  top: 30px;
  right: -15px;
  box-shadow: 2px 2px 4px #00000014;
}

.rdp-caption_label {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
}

.rdp-head_cell {
  text-transform: capitalize;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.rdp-cell {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.rdp-day_selected, .rdp-day_selected:hover {
  color: var(--primary-green);
  background-color: var(--secondary-green-hue);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: var(--secondary-green-hue);
}

.rdp-nav_button {
  border-radius: 65.9633px;
  box-shadow: 0 1px 1px #000e330d;
}

.rdp-day_range_end, .rdp-day_range_start {
  background: var(--primary-green);
  color: var(--background-white);
  border-radius: 5px;
  box-shadow: 0 1.21665px .811101px #00000040, 0 2.4333px 1.6222px #7d7d7d33;
}

.rdp-day_range_end:hover, .rdp-day_range_start:hover {
  background: var(--primary-green);
  color: var(--background-white);
}

@media only screen and (device-width <= 744px) {
  .calendar > .calendar-date {
    visibility: hidden;
    display: none;
  }
}

.customer-documents {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.customer-documents .customer-documents-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 82px;
  margin-left: auto;
  margin-right: 20px;
  display: flex;
}

.customer-documents .customer-documents-list {
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  margin-top: 12px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}

.customer-documents .customer-documents-help-text {
  color: var(--primary-dark-2);
  align-self: center;
  margin-top: 80px;
}

.customer-documents .secondary-button {
  align-self: center;
  margin-top: 12px;
  margin-bottom: 100px;
}

@media only screen and (device-width <= 744px) {
  .customer-documents .customer-documents-header {
    margin-top: 50px;
    margin-right: 40px;
  }

  .customer-documents .customer-documents-list {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.date-and-document {
  background: var(--background-white);
  border: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 16px 0;
  display: flex;
}

.date-and-document .date-and-document-date {
  color: var(--primary-dark-2);
  margin-bottom: 18px;
}

.date-and-document .date-and-document-body {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.date-and-document .date-and-document-body .date-and-document-text {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.date-and-document .date-and-document-body .date-and-document-text .document-summary {
  color: var(--primary-dark-1);
  margin-bottom: 2px;
}

.date-and-document .date-and-document-body .date-and-document-text .document-info {
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-top: 2px;
  display: flex;
}

.date-and-document .date-and-document-body .date-and-document-text .document-info img {
  height: 2px;
}

.date-and-document .date-and-document-body .date-and-document-text .document-info .body-small-regular {
  color: var(--primary-dark-3);
}

.date-and-document .date-and-document-body .download-button {
  margin-left: auto;
}

.dates-and-documents-wrapper {
  background: var(--background-white);
  border-radius: 0;
  flex-direction: column;
  flex-grow: .5;
  justify-content: stretch;
  display: flex;
  box-shadow: 0 4px 20px #0000000d;
}

.dates-and-documents-wrapper .dates-and-documents {
  background: var(--background-white);
  border-radius: 0;
  flex-direction: column;
  justify-content: stretch;
  padding: 40px 130px 40px 60px;
  display: flex;
}

.dates-and-documents-wrapper .dates-and-documents .header-wrapper {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.dates-and-documents-wrapper .dates-and-documents .header-wrapper .header-text-wrapper {
  flex-direction: column;
  display: flex;
}

.dates-and-documents-wrapper .dates-and-documents .header-wrapper .header-text-wrapper .label-small-allcaps {
  color: var(--secondary-blue-green);
}

.dates-and-documents-wrapper .dates-and-documents .header-wrapper .header-text-wrapper .h1-headline-light {
  color: var(--primary-green);
  margin-top: 4px;
  margin-bottom: 36px;
}

.dates-and-documents-wrapper .dates-and-documents .dates-and-documents-list .date-and-document:not(:last-child) {
  border-bottom: 1px solid var(--background-divider);
}

.dates-and-documents-wrapper .dates-and-documents .dates-and-documents-soon .dates-and-documents-soon-title {
  color: var(--primary-dark-5);
  margin-top: 24px;
}

.dates-and-documents-wrapper .dates-and-documents .dates-and-documents-soon .date-and-document:not(:last-child) {
  border-bottom: 1px solid var(--background-divider);
}

.dates-and-documents-wrapper .dates-and-documents .body-small-regular {
  color: var(--primary-dark-2);
  align-self: center;
  margin-top: auto;
}

.dates-and-documents-wrapper .dates-and-documents .archive-link {
  color: --primary-dark-1;
  cursor: pointer;
  align-self: center;
  margin-top: 4px;
}

@media only screen and (device-width <= 744px) {
  .dates-and-documents-wrapper {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    padding: 0;
    display: block;
    position: fixed;
  }

  .dates-and-documents-wrapper .dates-and-documents {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    padding: 0;
    display: block;
    position: fixed;
    top: 87%;
  }

  .dates-and-documents-wrapper .dates-and-documents .header-wrapper .header-text-wrapper .label-small-allcaps {
    margin-top: 33px;
    margin-left: 27px;
  }

  .dates-and-documents-wrapper .dates-and-documents .header-wrapper .header-text-wrapper .h1-headline-light {
    margin-bottom: 27px;
    margin-left: 27px;
    margin-right: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
  }

  .dates-and-documents-wrapper .dates-and-documents .toggle-arrow {
    background-image: url("dates-and-documents-up.e61b616c.svg");
    border-top-right-radius: 20px;
    width: 126px;
    height: 110px;
    margin-right: -1px;
  }

  .dates-and-documents-wrapper .dates-and-documents .dates-and-documents-list {
    margin-left: 35px;
    margin-right: 35px;
  }

  .dates-and-documents-wrapper .dates-and-documents .dates-and-documents-soon {
    visibility: hidden;
    display: none;
  }

  .dates-and-documents-wrapper .dates-and-documents .body-small-regular {
    text-align: center;
  }

  .dates-and-documents-wrapper .dates-and-documents .body-standard-semibold {
    text-align: center;
    margin-bottom: 20px;
  }

  .dates-and-documents-wrapper .focus-background {
    z-index: 1;
    opacity: .4;
    background-color: #000;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .dates-and-documents-wrapper .move-up {
    z-index: 2;
    height: 100%;
    animation: .5s ease-out forwards move-up;
  }

  .dates-and-documents-wrapper .move-up .toggle-arrow {
    background-image: url("dates-and-documents-down.652c64c7.svg");
  }

  .dates-and-documents-wrapper .move-down {
    height: 100%;
    animation: .5s ease-out forwards move-down;
  }

  @keyframes move-down {
    from {
      height: 100%;
      top: 5%;
    }

    to {
      top: 87%;
    }
  }

  @keyframes move-up {
    from {
      top: 87%;
    }

    to {
      height: 100%;
      top: 5%;
    }
  }
}

.customer-details {
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  height: 100%;
  display: flex;
}

.customer-details .app-body {
  background: var(--background-gray);
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  display: flex;
}

.customer-details .app-body .customer-details-body {
  flex-direction: row;
  flex: 1;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
  display: flex;
}

.customer-details .app-body .customer-details-body .customer-details-left {
  flex-direction: column;
  flex-grow: 2;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
  display: flex;
}

.customer-details .app-body .customer-details-body .customer-details-left .back-button {
  align-self: flex-start;
  margin-top: 24px;
  margin-left: 3%;
}

.customer-details .app-body .customer-details-body .customer-details-left .customer-details-left-body-wrapper-row {
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.customer-details .app-body .customer-details-body .customer-details-left .customer-details-left-body-wrapper-row .customer-details-left-body-wrapper-column {
  flex-direction: column;
  flex-grow: .3;
  justify-content: stretch;
  align-items: stretch;
  display: flex;
}

.admin-profile-content {
  background-color: var(--background-white);
  border: 1px solid var(--background-white);
  border-radius: 10px;
  flex-direction: column;
  flex-grow: .5;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60%;
  max-width: 800px;
  max-height: 150px;
  margin-top: 18px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 40px 30px 42px 44px;
  display: flex;
}

.admin-profile-content .admin-label {
  color: var(--primary-dark-2);
}

.admin-profile-content .label-xsmall-allcaps {
  color: var(--primary-dark-3);
}

.admin-profile-content .personal-label {
  margin-top: 41px;
}

.admin-profile-content .email-wrapper {
  flex-direction: row;
  margin-top: 21px;
  margin-left: 25px;
  display: flex;
}

.admin-profile-content .email-wrapper .profile-email {
  white-space: nowrap;
  margin-left: 16px;
}

@media only screen and (device-width <= 744px) {
  .admin-profile-content {
    width: 70%;
  }
}

.claims-profile-content {
  background-color: var(--background-white);
  border: 1px solid var(--background-white);
  border-radius: 10px;
  flex-direction: column;
  flex-grow: .5;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60%;
  max-width: 800px;
  max-height: 200px;
  margin: 18px 20px 20px;
  padding: 40px 30px 42px 44px;
  display: flex;
}

.claims-profile-content .label-xsmall-allcaps {
  color: var(--primary-dark-3);
}

.claims-profile-content .personal-label {
  margin-top: 41px;
}

.claims-profile-content .email-wrapper {
  flex-direction: row;
  margin-top: 21px;
  margin-left: 25px;
  display: flex;
}

.claims-profile-content .email-wrapper .profile-email {
  white-space: nowrap;
  margin-left: 16px;
}

.claims-profile-content .profile-help-text {
  max-width: 260px;
  color: var(--primary-dark-2);
  text-align: center;
  align-self: center;
  margin-top: 50px;
}

.claims-profile-content .profile-help-text .profile-help-number {
  color: var(--primary-dark-1);
  display: inline-block;
}

@media only screen and (device-width <= 744px) {
  .profile-content {
    width: 70%;
  }
}

.profile-content {
  background-color: var(--background-white);
  border: 1px solid var(--background-white);
  border-radius: 10px;
  flex-direction: column;
  flex-grow: .5;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60%;
  max-width: 800px;
  max-height: 550px;
  margin-top: 18px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 40px 30px 42px 44px;
  display: flex;
}

.profile-content .affiliate-label {
  color: var(--primary-dark-2);
}

.profile-content .label-xsmall-allcaps {
  color: var(--primary-dark-3);
}

.profile-content .personal-label {
  margin-top: 41px;
}

.profile-content .email-wrapper {
  flex-direction: row;
  margin-top: 21px;
  margin-left: 25px;
  display: flex;
}

.profile-content .email-wrapper .profile-email {
  white-space: nowrap;
  margin-left: 16px;
}

.profile-content .contact-wrapper {
  flex-direction: row;
  margin-top: 38px;
  margin-left: 25px;
  display: flex;
}

.profile-content .contact-wrapper .profile-contact {
  margin-left: 16px;
}

.profile-content .address-wrapper {
  flex-direction: row;
  margin-top: 29px;
  margin-left: 25px;
  display: flex;
}

.profile-content .address-wrapper img {
  height: 21px;
}

.profile-content .address-wrapper .address-text-wrapper {
  margin-left: 16px;
}

.profile-content .firm-label {
  margin-top: 44px;
}

.profile-content .firm-wrapper {
  flex-direction: row;
  margin-top: 12px;
  margin-left: 25px;
  display: flex;
}

.profile-content .firm-wrapper .profile-firm-label {
  color: var(--primary-dark-3);
}

.profile-content .firm-wrapper .profile-firm {
  margin-left: 12px;
}

.profile-content .city-wrapper {
  flex-direction: row;
  margin-top: 24px;
  margin-left: 25px;
  display: flex;
}

.profile-content .city-wrapper .profile-city-label {
  color: var(--primary-dark-3);
}

.profile-content .city-wrapper .profile-city {
  margin-left: 16px;
}

.profile-content .state-wrapper {
  flex-direction: row;
  margin-top: 24px;
  margin-left: 25px;
  display: flex;
}

.profile-content .state-wrapper .profile-state-label {
  color: var(--primary-dark-3);
}

.profile-content .state-wrapper .profile-state {
  margin-left: 8px;
}

.profile-content .profile-help-text {
  max-width: 260px;
  color: var(--primary-dark-2);
  text-align: center;
  align-self: center;
  margin-top: 50px;
}

.profile-content .profile-help-text .profile-help-number {
  color: var(--primary-dark-1);
  display: inline-block;
}

@media only screen and (device-width <= 744px) {
  .profile-content {
    width: 70%;
  }
}

.profile {
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  height: 100%;
  display: flex;
}

.profile .app-body {
  background: var(--background-gray);
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  display: flex;
}

.profile .app-body .profile-body {
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  height: 100%;
  display: flex;
}

.profile .app-body .profile-body .back-button {
  align-self: flex-start;
  margin-top: 24px;
  margin-left: 3%;
}

.profile .app-body .profile-body .profile-content, .profile .app-body .profile-body .admin-profile-content {
  margin-bottom: 27px;
}

.profile .app-body .profile-body .sign-out-button {
  margin-bottom: 35px;
}

.welcome-content {
  flex-direction: column;
  flex: 1;
  align-items: center;
  display: flex;
}

.welcome-content .body-large-semibold {
  color: var(--primary-green);
  text-align: center;
  flex: none;
  order: 1;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 5px;
  display: flex;
}

.welcome-content .large-type {
  text-align: center;
  color: var(--primary-dark-2);
  flex: 1;
  order: 2;
  align-items: center;
  max-width: 713px;
  margin: 5px 13px;
  display: flex;
}

.welcome-content .body-large-light {
  color: var(--primary-dark-3);
  text-align: center;
  flex: none;
  order: 3;
  align-items: center;
  max-width: 588px;
  margin: 5px 42px 0;
  display: flex;
}

@media only screen and (device-width <= 390px) {
  .welcome-content > .body-large-light {
    max-width: 305px;
  }

  .welcome-content > .large-type {
    letter-spacing: 0;
    max-width: 355px;
    font-size: 36px;
    font-weight: 100;
    line-height: 42px;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .welcome-content > .body-large-light {
    max-width: 388px;
  }

  .welcome-content > .large-type {
    max-width: 435px;
  }
}

.welcome-logos {
  background-color: var(--primary-dark-4);
  min-width: 390px;
  padding-top: 52px;
  padding-bottom: 52px;
  position: relative;
}

.welcome-logos .welcome-logos-wrapper {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
  display: flex;
}

.welcome-logos .welcome-logos-wrapper .cst-logo {
  width: 87px;
}

.welcome-logos .welcome-logos-wrapper .intoxalock-logo {
  width: 116px;
}

.welcome-logos .welcome-logos-wrapper .breathe-easy-logo {
  width: 118px;
}

.welcome-logos .welcome-logos-wrapper .new-directions-logo {
  width: 52px;
}

.welcome-logos .scroll-wrapper-secondary {
  visibility: hidden;
  display: none;
}

@media only screen and (device-width <= 470px) {
  .welcome-logos {
    overflow-x: hidden;
  }

  .welcome-logos .welcome-logos-wrapper {
    position: absolute;
    top: 35%;
  }

  .welcome-logos .scroll-wrapper-primary {
    animation: 10s linear infinite primary;
  }

  .welcome-logos .scroll-wrapper-secondary {
    visibility: inherit;
    animation: 10s linear infinite secondary;
    display: flex;
  }

  @keyframes primary {
    from {
      left: 0%;
    }

    to {
      left: -130%;
    }
  }

  @keyframes secondary {
    from {
      left: 130%;
    }

    to {
      left: 0%;
    }
  }
}

.welcome {
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  height: 100%;
  display: flex;
}

.welcome .app-body {
  flex-direction: column;
  flex: 1;
  align-items: center;
  display: flex;
}

.welcome .app-body .welcome-content {
  flex-grow: 0;
  order: 1;
  display: flex;
}

.welcome .app-body .button-link-create-account {
  background-color: var(--primary-dark-5);
  order: 2;
  margin-top: 60px;
  display: flex;
  position: relative;
}

.welcome .app-body .image-background {
  background-color: var(--background-divider);
  flex-direction: column;
  flex: auto;
  order: 3;
  align-items: center;
  width: 100%;
  margin-top: -28px;
  display: flex;
  overflow-y: hidden;
}

.welcome .app-body .image-background .preview {
  display: relative;
  background-image: url("preview-duidash-large.d72d78ba.png");
  background-repeat: no-repeat;
  background-size: clamp(0px, 95vw, 1019px);
  width: clamp(0px, 95vw, 1019px);
  height: 600px;
  margin-top: 75px;
  overflow-y: hidden;
}

.welcome .app-body .image-background .preview img {
  display: relative;
}

@media only screen and (device-width <= 390px) {
  .welcome > .app-body > .image-background > .preview {
    display: relative;
    background-image: url("preview-duidash-small.01be239d.png");
    background-size: 219px;
    width: 219px;
    height: 401px;
    margin-top: 75px;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .welcome > .app-body > .image-background > .preview {
    background-image: url("preview-duidash-large.d72d78ba.png");
    background-repeat: no-repeat;
    background-size: clamp(0px, 95vw, 617px);
    width: clamp(0px, 95vw, 617px);
    height: 400px;
  }
}

.card-affiliate {
  background-color: var(--background-white);
  border: 1px solid var(--background-white);
  cursor: pointer;
  border-radius: 8px;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 0;
  min-height: 98px;
  padding: 40px;
  display: flex;
}

.card-affiliate .card-affiliate-name {
  border-radius: 8px;
  flex-direction: column;
  min-width: 0;
  display: flex;
}

.card-affiliate .card-affiliate-name .affiliate-header-wrapper {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.card-affiliate .card-affiliate-name .affiliate-header-wrapper .affiliate-unverified-label {
  color: #995900;
  margin-right: 8px;
}

.card-affiliate .card-affiliate-name .affiliate-header-wrapper .affiliate-unverified-label img {
  margin-right: 5px;
}

.card-affiliate .card-affiliate-name .affiliate-name {
  color: var(--primary-dark-1);
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 700px;
  margin-top: 20px;
  overflow: hidden;
}

.card-affiliate .card-affiliate-firm {
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  min-width: 0;
  display: flex;
}

.card-affiliate .card-affiliate-firm .card-affiliate-location-wrapper {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  display: flex;
}

.card-affiliate .card-affiliate-firm .card-affiliate-location-wrapper .card-affiliate-firm-city {
  color: var(--primary-dark-2);
  white-space: nowrap;
}

.card-affiliate .card-affiliate-firm .card-affiliate-location-wrapper img {
  height: 2px;
}

.card-affiliate .card-affiliate-firm .card-affiliate-location-wrapper .card-affiliate-firm-state {
  color: var(--primary-dark-2);
  white-space: nowrap;
}

.card-affiliate:hover {
  background: linear-gradient(90deg, #2b93ff33 0%, #e4f1ff33 100%), #fff;
  box-shadow: 2px 2px 4px #00000014;
}

.affiliate-unverified:hover {
  background: linear-gradient(90deg, #ffc95833 0%, #fff8e933 100%), #fff;
}

.affiliate-verified:hover {
  background: linear-gradient(90deg, #2b93ff33 0%, #e4f1ff33 100%), #fff;
}

.firm-associate-wrapper {
  gap: 1.5rem;
  display: flex;
}

.firm-associate-wrapper .card-affiliate-firm-name {
  text-align: center;
  color: var(--primary-dark-1);
}

@media only screen and (width <= 744px) {
  .card-affiliate {
    border-radius: 20px;
    flex-direction: column;
    margin-left: 21px;
    margin-right: 21px;
    padding: 28px 22px 20px;
    overflow: hidden;
    box-shadow: 2px 2px 4px #00000014;
  }

  .card-affiliate .card-affiliate-name {
    align-self: flex-start;
  }

  .card-affiliate .card-affiliate-firm {
    align-items: flex-start;
  }

  .affiliate-unverfied {
    background: linear-gradient(90deg, #ffc95833 0%, #fff8e933 100%), #fff;
  }

  .affiliate-verfied {
    background: linear-gradient(90deg, #2b93ff33 0%, #e4f1ff33 100%), #fff;
  }

  .card-affiliate .card-affiliate-name .affiliate-name {
    width: 380px;
  }
}

@media only screen and (width <= 390px) {
  .card-affiliate {
    flex-direction: column;
  }

  .card-affiliate .card-affiliate-name .affiliate-name {
    width: 280px;
  }
}

.admin-paging-control {
  flex-direction: row;
  align-self: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.admin-paging-control .admin-paging-button {
  padding: 10px;
}

.admin-paging-control .admin-paging-button:active {
  background: var(--primary-dark-2);
}

.admin-paging-control .admin-paging-message {
  color: var(--primary-dark-2);
}

.admin-paging-control .admin-paging-arrow-back {
  transform: scaleX(-1);
}

.firm-sort-filter {
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin: 25px auto;
  display: flex;
}

.firm-sort-filter .firm-filters-sort {
  flex-flow: wrap;
  justify-content: flex-start;
  width: 100%;
  display: flex;
}

.firm-sort-filter .firm-filters-sort .sort-button {
  background-color: var(--primary-dark-5);
  margin-left: auto;
  display: flex;
}

@media only screen and (device-width <= 390px) {
  .firm-sort-filter {
    width: 90%;
  }

  .firm-filters-sort .sort-button {
    margin-right: 0;
  }
}

@media only screen and (device-width >= 391px) and (device-width <= 744px) {
  .firm-sort-filter {
    width: 90%;
  }
}
/*# sourceMappingURL=index.c6b27416.css.map */
