.affiliate-details {
   display: flex;
   flex-direction: column;
   align-items: stretch;
   flex: auto;
   height: 100%;

   .app-body {
      display: flex;
      flex: auto;
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;
      flex-grow: 2;
      height: 100%;
      background: var(--background-gray);

      .back-button {
         align-self: flex-start;
         margin-top: 24px;
         margin-left: 3%;
      }

      .affiliate-details-wrapper-row {
         display: flex;
         flex-direction: row;
         justify-content: center;
         align-items: stretch;
         margin-top: 20px;
         margin-left: 10px;
         margin-right: 10px;

         .affiliate-details-wrapper-column {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            align-items: stretch;
            flex-grow: 0.3;
            background-color: var(--background-white);
            border: 1px solid var(--background-white);
            border-radius: 10px;
            padding: 40px 60px 45px 60px;
            margin-bottom: 40px;

            .tab-container {
               display: flex;
               justify-content: flex-start;
               align-items: center;
               flex-direction: row;
               width: 100%;
               margin-bottom: 20px;
               border-bottom: 1px solid var(--primary-dark-4);
               transition: all .5s ease-in-out;

               .tab {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  width: 80%;
                  gap: 15px;

                  .tab-button {
                     font-family: 'Roboto';
                     font-size: 26px;
                     padding: 10px 20px;
                     border: 1px solid var(--primary-dark-4);
                     border-bottom: 0px;
                     background-color: var(--background-white);
                     box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
                     color: var(--primary-dark-1);
                     border-radius: 4px;
                     border-bottom-left-radius: 0px;
                     border-bottom-right-radius: 0px;
                     transition: background-color 0.3s;
                     cursor: pointer;

                     &:hover {
                        background: linear-gradient(90deg, #2b93ff33 0%, #e4f1ff33 100%), #fff;
                        color: var(--primary-dark-1);
                     }

                     &.active {
                        color: var(--background-white);
                        background-color: var(--primary-dark-1);
                        border: 1px solid transparent;
                        &:hover {
                           background-color: var(--primary-dark-1);
                        }
                     }
                  }
               } 
            }

            .client-list-label {
               margin-top: 30px;
               color: var(--primary-dark-2);
            }

            .affiliate-customer-item {
               border-top: 1px solid var(--background-divider);
               margin-top: 20px;

               .affiliate-customer-item-id {
                  color: var(--primary-dark-2);
                  margin-top: 20px;
               }

               .affiliate-customer-item-name {
                  margin-top: 8px;
               }
            }

            .affiliate-details-unverified-background {
               display: flex;
               flex-direction: column;
               justify-content: center;
               align-items: center;
               background-color: var(--background-divider);
               border-radius: 10px;
               margin-top: 23px;
               height: 319px;

               img {
                  margin-bottom: 32px;
               }

               .affiliate-details-unverified-message {
                  color: var(--primary-dark-2);
                  text-align: center;
                  margin-left: 10px;
                  margin-right: 10px;
               }
            }
         }
      }
   }
}

@media only screen and (max-device-width: 391px) {
   .affiliate-details
      > .app-body
      > .affiliate-details-wrapper-row
      > .affiliate-details-wrapper-column {
      padding: 20px 30px 25px 30px;
   }
}
